import React from 'react'
import { graphql } from 'gatsby'
import { Image, Header, Segment, List } from 'semantic-ui-react'
import Img from 'gatsby-image'

import Layout from '../components/layout'

const IndexPage = props => (
  <Layout>
    <Segment.Group raised>
      <Segment>
        <a href={`${process.env.STRAND_URL}`}>
          <Header as="h1">
            Strand Cottage
            <Header.Subheader>
              Self-Catering accommodation in Ballycastle, Co. Antrim.
            </Header.Subheader>
          </Header>
          <Image fluid>
            <Img fluid={props.data.imageStrandExterior.childImageSharp.fluid} />
          </Image>
        </a>
      </Segment>

      <Segment>
        <a href={`${process.env.GLENAAN_URL}`}>
          <Header as="h1">
            Glenaan Cottage
            <Header.Subheader>
              Self-Catering Accommodation high in the Glens just outside
              Cushendall , Co. Antrim.
            </Header.Subheader>
          </Header>
          <Image fluid>
            <Img
              fluid={props.data.imageGlenaanExterior.childImageSharp.fluid}
            />
          </Image>
        </a>
      </Segment>
    </Segment.Group>

    <Segment basic textAlign="center">
      <List link horizontal divided>
        <List.Item as="a" href={`${process.env.HUB_URL}`}>
          Check out our other properties...
        </List.Item>
        <List.Item>Privacy policy</List.Item>
        <List.Item>
          Copyright © {new Date().getFullYear()}. All Rights Reserved.
        </List.Item>
        <List.Item>Built by James McDonnell</List.Item>
      </List>
    </Segment>
  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 6000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    imageStrandExterior: file(
      relativePath: { eq: "strand/Strand-Cottage-Exterior.JPG" }
    ) {
      ...fluidImage
    }
    imageGlenaanExterior: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-Exterior.JPG" }
    ) {
      ...fluidImage
    }
  }
`