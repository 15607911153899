import React, { Component } from 'react'
import { Container, Menu, Visibility } from 'semantic-ui-react'
import { Link } from 'gatsby'

const LinkedItem = ({ children, ...props }) => (
  <Menu.Item as={Link} activeClassName="active" {...props}>
    {children}
  </Menu.Item>
)

export default class StickyNav extends Component {
  state = {
    menuFixed: false,
  }

  stickTopMenu = () => this.setState({ menuFixed: true })

  unStickTopMenu = () => this.setState({ menuFixed: false })

  render() {
    const { menuFixed } = this.state

    return (
      <div>
        {/* Attaching the top menu is a simple operation, we only switch `fixed` prop and add another style if it has
            gone beyond the scope of visibility
          */}
        <Visibility
          onBottomPassed={this.stickTopMenu}
          onBottomVisible={this.unStickTopMenu}
          once={false}
        >
          <Container>
            <Menu 
             compact
            fixed={menuFixed ? 'top' : undefined}>
              <LinkedItem to="/" exact="true">Home</LinkedItem>          
              <LinkedItem to="#enquire">Enquire</LinkedItem>
              <LinkedItem to="#gallery">Gallery</LinkedItem>
              <LinkedItem to="#explore">Explore</LinkedItem>
              <LinkedItem to="#contact">Contact</LinkedItem>
            </Menu>
          </Container>
        </Visibility>
      </div>
    )
  }
}
