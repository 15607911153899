import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Grid } from 'semantic-ui-react'

import { Header } from '@ballycastle-accommodation/shared-ui'

import 'semantic-ui-less/semantic.less'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
            description
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet
          title={
            data.site.siteMetadata.title +
            ': ' +
            data.site.siteMetadata.subtitle
          }
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
          ]}
        />

        <Header siteTitle={data.site.siteMetadata.title} />

        <Container>
          <Grid relaxed stackable>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              {children}
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    )}
  />
)

Layout.propTypes = {
  //children: PropTypes.func
  children: PropTypes.node.isRequired,
}

export default Layout
